<template>
  <div class="content-body">
    <!-- row -->
    <div class="container-fluid">
      <!-- batas -->
      <div class="card">
        <div class="card-header border-0 pb-0 d-sm-flex d-block">
          <h4 class="card-title mb-1">Sertifikat</h4>
          <div class="dropdown ml-auto text-right">
            <div class="btn-link" data-toggle="dropdown">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="12" cy="5" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="19" r="2"></circle>
                </g>
              </svg>
            </div>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" href="profile.html">Lihat Riwayat</a>
            </div>
          </div>
        </div>
        <div class="card-body pt-0 p-0">
          <!-- untuk add -->
          <div class="align-items-center row mx-0 border-bottom p-4">
            <span class="number col-2 col-sm-1 px-0 align-self-center">#1</span>
            <div class="rounded-circle p-3 mr-3">
              <img src="/assets/images/img-bs/content/sertifikat-succes.png" />
            </div>
            <div class="col-sm-4 col-12 col-xxl-5 my-3 my-sm-0 px-0">
              <h3 class="fs-20 font-w600">Selamat</h3>
              <span class="mt-3"
                >Kamu telah mengikuti event <br /><strong> Site Visite</strong>
                PT.Bintang Sempurna
              </span>
            </div>
            <div
              class="ml-sm-auto col-5 col-sm-2 px-0 d-flex align-self-center align-items-center"
            >
              <div class="text-center">
                <span class="badge light badge-success mt-3">
                  <i class="fa fa-circle text-success mr-1"></i>
                  Berhasil
                </span>
              </div>
            </div>
            <div
              class="ml-sm-auto col-7 col-sm-2 px-0 d-flex align-self-center align-items-center"
            >
              <div class="text-center">
                <a
                  href="https://onlineprint.co.id/id/sertifikat-a4/"
                  type="button"
                  class="btn btn-info btn-xs"
                >
                  <i class="flaticon-381-print"></i>
                  Print</a
                >
                <button type="button" class="btn btn-danger btn-xs">
                  <i class="flaticon-381-download"></i>
                  Download
                </button>
              </div>
            </div>
          </div>

          <!-- untuk add -->
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>
