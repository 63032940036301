<template>
  <div class="content-body" style="padding-top: 10rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Edit Career' }">Data Career</router-link>
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Form {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <!-- Division Dropdown -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Division</label>
                  <div class="col-sm-9">
                    <select
                      v-model="post.id_division"
                      @change="onDivisionChange"
                      class="form-control"
                    >
                      <option value="" disabled>Pilih Division</option>
                      <option
                        v-for="division in divisionList"
                        :key="division.id"
                        :value="division.id"
                      >
                        {{ division.name_division }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- Jobs Dropdown (Based on Division) -->
                <div class="form-group row" v-if="jobsList.length > 0">
                  <label class="col-sm-3 col-form-label">Jobs</label>
                  <div class="col-sm-9">
                    <select v-model="post.id_type" class="form-control">
                      <option value="" disabled>Pilih Jobs</option>
                      <option
                        v-for="job in jobsList"
                        :key="job.id"
                        :value="job.id"
                      >
                        {{ job.name_type }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Company</label>
                  <div class="col-sm-9">
                    <select v-model="post.id_company" class="form-control">
                      <option value="">Pilih Kantor</option>
                      <option value="1">Pusat</option>
                      <option value="2">Cabang</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Deskripsi</label>
                  <textarea
                    ref="summernote"
                    class="form-control summernote"
                    id="deskripsi"
                    v-model="post.deskripsi"
                    rows="3"
                  ></textarea>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Status</label>
                  <div class="col-sm-9">
                    <select v-model="post.is_publish" class="form-control">
                      <option value="true">Publish</option>
                      <option value="false">Unpublish</option>
                    </select>
                  </div>
                </div>

                <button type="button" @click="goBack" class="btn btn-warning">
                  Kembali
                </button>
                <button type="button" @click="submit" class="btn btn-primary">
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "Edit Career",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const summernote = ref(null);
    const currentRouteName = computed(() => router.name);
    const post = ref({
      id_division: "",
      id_type: "",
      name_type: "",
      id_company: "",
      deskripsi: "",
      is_publish: "true",
      user: null,
    });
    const divisionList = ref([]);
    const jobsList = ref([]);

    const token = computed(() => store.state.token);

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          post.value.user = response.data.id;
        } else {
          Swal.fire({
            title: "Error",
            text: "Failed to fetch user data",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            window.location.href = "/";
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = "/";
        });
      }
    };

    const fetchCareer = async () => {
      const careerId = route.params.id;
      try {
        const response = await axiosInstance.get(`/career/${careerId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          post.value = response.data;
        } else {
          Swal.fire({
            title: "Error",
            text: "Failed to fetch career data",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const fetchDivisionList = async () => {
      try {
        const response = await axiosInstance.get("/division", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          divisionList.value = response.data.data;
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const fetchJobsByDivision = async (divisionId) => {
      try {
        const response = await axiosInstance.get(
          `/jobs-division/${divisionId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );
        if (response.status === 200) {
          jobsList.value = response.data.data;
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const onDivisionChange = async () => {
      if (post.value.id_division) {
        await fetchJobsByDivision(post.value.id_division);
      } else {
        jobsList.value = []; // Clear jobs list if no division selected
      }
    };

    const goBack = () => {
      router.push({ name: "Career" });
    };

    const submit = async () => {
      post.value.deskripsi = $("#deskripsi").summernote("code");
      console.log("Deskripsi Summernote:", post.value.deskripsi);
      const careerId = route.params.id;
      const formData = new FormData();
      formData.append("id_division", post.value.id_division);
      formData.append("id_type", post.value.id_type);
      formData.append("id_company", post.value.id_company);
      formData.append("deskripsi", post.value.deskripsi);
      formData.append("is_publish", post.value.is_publish);
      formData.append("user", post.value.user);

      try {
        const response = await axiosInstance.post(
          `/career-update/${careerId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Data updated successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            goBack();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Error updating data",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    onMounted(async () => {
      await fetchUser();
      await fetchDivisionList();
      await fetchCareer();
      await fetchJobsByDivision(post.value.id_division);

      $(summernote.value).summernote({
        height: 200,
        callbacks: {
          onChange: (contents) => {
            post.value.deskripsi = contents;
          },
        },
      });
    });

    return {
      currentRouteName,
      post,
      divisionList,
      jobsList,
      onDivisionChange,
      submit,
      goBack,
    };
  },
};
</script>
