<template>
    <div class="modal fade" id="SkModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content modal-lg">
          <div class="modal-header">
            <h5 class="modal-title">Form</h5>
            <button type="button" class="close" data-dismiss="modal"><span>&times;</span></button>
          </div>
          <div class="modal-body">
            <div v-if="judulError" class="error-message">{{ judulError }}</div>
            <div class="basic-form">
              <div class="form-group">
                <label>Kategori</label>
                <select v-model="formData.id_kategori" class="form-control">
                  <option v-for="kategori in kategoriList" :key="kategori.id" :value="kategori.id">
                    {{ kategori.judul }}
                  </option>
                </select>
              </div>
  
              <div class="form-group">
                <label>Sub Kategori</label>
                <input type="text" class="form-control" v-model="formData.judul" id="subkategori">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger light" data-dismiss="modal">Tutup</button>
            <button type="button" @click.prevent="submit" class="btn btn-primary">Simpan</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { reactive, ref, onMounted, watch, computed } from "vue";
  import { useStore } from "vuex";
  import axiosInstance from "../../axios";
  import Swal from "sweetalert2";
  
  export default {
    name: "ModalSk",
    props: {
      isEdit: Boolean,
      editData: Object,
    },
    setup(props, { emit }) {
      const formData = reactive({
        id_kategori: "",
        judul: "",
        user : null
      });
      const store = useStore();
      const kategoriList = ref([]);
      const judulError = ref("");
      const token = computed(() => store.state.token);
  
      const fetchUser = async () => {
        try {
            const response = await axiosInstance.get('/user', {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.value}`, 
            },
            });
            if (response.status === 200) {
            formData.user = response.data.id;
            } else {
            Swal.fire({
                title: "Error",
                text: error.message,
                icon: "error",
                confirmButtonText: "OK"
            })
            .then(() => {
                store.dispatch("setAuth", false);
                store.dispatch("setToken", { token: null, expiry: null });
                localStorage.clear();
                window.location.href = '/'
            });
            //throw new Error('Failed to fetch user data');
            }
        } catch (error) {
            Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK"
            })
            .then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            window.location.href = '/'
            });
        }
        };
      const fetchCategories = async () => {
        try {
          const response = await axiosInstance.get("/kategori", {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${store.state.token}`,
            },
          });
          if (response.status === 200) {
            kategoriList.value = response.data.data;
          }
        } catch (error) {
          Swal.fire({
            title: "Error",
            text: `Error: ${error.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      };
  
      const validateForm = () => {
        if (!formData.id_kategori || !formData.judul) {
          judulError.value = "All fields are required";
          return false;
        }
        judulError.value = "";
        return true;
      };
  
      watch(
        () => props.editData,
        (newValue) => {
          if (newValue) {
            formData.id_kategori = newValue.id_kategori;
            formData.judul = newValue.judul;
            formData.user = newValue.created_by;
          } else {
            formData.id_kategori = "";
            formData.judul = "";
            formData.user = "";
          }
        },
        { immediate: true }
      );
  
      const submit = () => {
        console.log("Form Data:", formData);
        if (!validateForm()) return;
  
        const payload = {
            ...formData,
            id_kategori: formData.id_kategori,
        };

        if (props.isEdit) {
          emit("updateData", payload);
        } else {
          emit("createData", payload);
        }
      };
  
      onMounted(() => {
        fetchUser();
        fetchCategories();
      });
  
      return {
        formData,
        kategoriList,
        judulError,
        submit,
      };
    },
  };
  </script>
  
  <style scoped>
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  </style>
  