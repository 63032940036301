<template>
    <div class="content-body" style="padding-top: 10rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <!-- Button Add -->
      <button
        type="button"
        class="btn btn-primary mb-2"
        data-toggle="modal"
        data-target="#SkModal"
        @click="openModal(false)"
      >
        Tambah
      </button>
      <!-- End Button Add -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Data {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <table class="table header-border table-responsive-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Kategori</th>
                    <th>Sub Kategori</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-if="!dataList || !dataList.length" class="text-center">
                        <td colspan="4">No Data</td>
                    </tr>
                    <tr v-for="(item, index) in dataList" :key="item.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.dat_kategori.judul }}</td>
                        <td>{{ item.judul }}</td>
                        <td>
                        <button
                            @click="openModal(true, item)"
                            type="button"
                            class="btn btn-primary"
                            data-toggle="modal"
                            data-target="#SkModal"
                        >
                            Edit
                        </button>
                        <button @click="deleteData(item.id)" type="button" class="btn btn-danger">
                            Delete
                        </button>
                        </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

   <!-- Modal -->
   <ModalSk :isEdit="isEdit" :editData="editData" @createData="createData" @updateData="updateData"/>
</template>

<script>
import { onMounted, ref, computed} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";
import ModalSk from "../components/modal/ModalSk.vue";
export default {
    name: "Kategori",
    components: {
        ModalSk
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const currentRouteName = computed(() => router.name);
        const dataList = ref([]);
        const isEdit = ref(false);
        const editData = ref(null);

        const isAuthenticated = computed(() => store.getters.isAuthenticated);
        const token = computed(() => store.getters.token);
        const tokenExpiry = computed(() => store.getters.tokenExpiry);


        const fetchData = async () => {
            if (
                !token.value ||
                !tokenExpiry.value ||
                new Date() > new Date(tokenExpiry.value) ||
                !isAuthenticated.value
            ) {
                Swal.fire({
                    title: "Error",
                    text: `Session expired or invalid`,
                    icon: "error",
                    confirmButtonText: "OK",
                }).then(() => {
                    store.dispatch("setAuth", false);
                    store.dispatch("setToken", { token: null, expiry: null });
                    localStorage.clear();
                    router.replace({ name: "Login" });
                });
                return;
            }

            try {
                const response = await axiosInstance.get("/sub-kategori", {
                headers: {
                    Authorization: `Bearer ${token.value}`,
                },
                });

                if (response.status === 200) {
                dataList.value = response.data.data;
                } else {
                Swal.fire({
                    title: "Error",
                    text: `Failed to fetch data`,
                    icon: "error",
                    confirmButtonText: "OK",
                });
                }
            } catch (error) {
                Swal.fire({
                title: "Error",
                text: error.message,
                icon: "error",
                confirmButtonText: "OK",
                });
            }
        };

        const openModal = (edit = false, data = null) => {
            isEdit.value = edit;
            editData.value = data;
        };

        const createData = async (formData) => {
            try {
                const response = await axiosInstance.post("/sub-kategori", formData, {
                headers: {
                    Authorization: `Bearer ${token.value}`,
                },
                });

                if (response.status === 200) {
                Swal.fire({
                    title: "Success",
                    text: "Data created successfully!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                await fetchData();
                $('#SkModal').modal('hide');
                } else {
                Swal.fire({
                    title: "Error",
                    text: `Error: ${response.data.message}`,
                    icon: "error",
                    confirmButtonText: "OK",
                });
                }
            } catch (error) {
                Swal.fire({
                title: "Error",
                text: `Error: ${error.message}`,
                icon: "error",
                confirmButtonText: "OK",
                });
            }
        };

        const updateData = async (formData) => {
            try {
                const response = await axiosInstance.put(`/sub-kategori/${editData.value.id}`, formData, {
                headers: {
                    Authorization: `Bearer ${token.value}`,
                },
                });

                if (response.status === 200) {
                Swal.fire({
                    title: "Success",
                    text: "Data updated successfully!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                await fetchData();
                $('#SkModal').modal('hide');
                } else {
                Swal.fire({
                    title: "Error",
                    text: `Error: ${response.data.message}`,
                    icon: "error",
                    confirmButtonText: "OK",
                });
                }
            } catch (error) {
                Swal.fire({
                title: "Error",
                text: `Error: ${error.message}`,
                icon: "error",
                confirmButtonText: "OK",
                });
            }
        };

        const deleteData = async (id) => {
            try {
                const response = await axiosInstance.delete(`/sub-kategori/${id}`, {
                headers: {
                    Authorization: `Bearer ${token.value}`,
                },
                });

                if (response.status === 200) {
                Swal.fire({
                    title: "Success",
                    text: "Data deleted successfully!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                await fetchData();
                $('#SkModal').modal('hide');
                } else {
                Swal.fire({
                    title: "Error",
                    text: `Error: ${response.data.message}`,
                    icon: "error",
                    confirmButtonText: "OK",
                });
                }
            } catch (error) {
                Swal.fire({
                title: "Error",
                text: `Error: ${error.message}`,
                icon: "error",
                confirmButtonText: "OK",
                });
            }
        };

        onMounted(fetchData);

        return {
            currentRouteName,
            dataList,
            isEdit,
            editData,
            openModal,
            createData,
            updateData,
            deleteData,
        }
    }
}
</script>