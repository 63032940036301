<template>
  <div class="content-body" style="padding-top: 10rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <!-- Button Add -->
      <button type="button" class="btn btn-primary mb-2" @click="openModal()">
        Tambah
      </button>
      <!-- End Button Add -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Data {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <table class="table header-border table-responsive-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Division</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!division.length" class="text-center">
                    <td colspan="3">No Data</td>
                  </tr>
                  <tr v-for="(division, index) in division" :key="division.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ division.name_division }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <ModalDivision
    v-if="isModalVisible"
    :divisionToEdit="divisionToEdit"
    @refreshData="fetchData"
    @closeModal="closeModal"
  />
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";
import ModalDivision from "@/components/modal/ModalDivision.vue";

export default {
  name: "Division",
  components: {
    ModalDivision,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const currentRouteName = computed(() => router.name);
    const division = ref([]);
    const divisionToEdit = ref(null);
    const isModalVisible = ref(false);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    const fetchData = async () => {
      if (
        !token.value ||
        !tokenExpiry.value ||
        new Date() > new Date(tokenExpiry.value) ||
        !isAuthenticated.value
      ) {
        Swal.fire({
          title: "Error",
          text: `Session expired or invalid`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.replace({ name: "Login" });
        });
        return;
      }
      try {
        const response = await axiosInstance.get("/division", {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          division.value = response.data.data;
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.push({ name: "Login" });
        });
      }
    };

    const openModal = (division = null) => {
      divisionToEdit.value = division;
      isModalVisible.value = true;
    };

    const closeModal = () => {
      isModalVisible.value = false;
    };

    onMounted(fetchData);

    return {
      currentRouteName,
      division,
      divisionToEdit,
      openModal,
      closeModal,
      isModalVisible,
      fetchData,
    };
  },
};
</script>

<style scoped>
.content-body {
  padding: 30px;
  background: #f4f4f9;
}
</style>
