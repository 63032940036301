<template>
  <div class="modal fade" id="kategoriModal">
    <div class="modal-dialog" role="document">
      <div class="modal-content modal-lg">
        <div class="modal-header">
          <h5 class="modal-title">Form</h5>
          <button type="button" class="close" data-dismiss="modal"><span>&times;</span></button>
        </div>
        <div class="modal-body">
          <div v-if="judulError" class="error-message">{{ judulError }}</div>
          <div class="basic-form">
            <div class="form-group">
              <label>Kategori</label>
              <input type="text" class="form-control" v-model="kategori.judul" placeholder="Nama Kategori">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger light" data-dismiss="modal">Tutup</button>
          <button type="button" @click.prevent="submit" class="btn btn-primary">Simpan</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: 'Modal',
  props: {
    kategoriToEdit: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const kategori = reactive({
      id: null,
      judul: ''
    });
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    const judulError = ref('');

    const validateForm = () => {
      if (!kategori.judul) {
        judulError.value = 'Nama kategori tidak boleh kosong';
        return false;
      }
      judulError.value = '';
      return true;
    };

    watch(() => props.kategoriToEdit, (newVal) => {
      if (newVal) {
        kategori.id = newVal.id;
        kategori.judul = newVal.judul;
      } else {
        kategori.id = '';
        kategori.judul = '';
      }
    }, { immediate: true });

    const submit = async () => {
      if(!token.value || !tokenExpiry.value || new Date() > new Date(tokenExpiry.value) || !isAuthenticated.value) {
        Swal.fire({
            title: "Error",
            text: `Session expired or invalid`,
            icon: "error",
            confirmButtonText: "OK"
        }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            window.location.href = "/";
        });
            return;
      }
      if (!validateForm()) return;

      try {
        const userResponse = await axiosInstance.get("/user", {
        // const userResponse = await proxy.$axios.get("https://api.dev.bintangsempurna.co.id/api/user", {
        //   method: "GET",
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (userResponse.status !== 200) {
            await Swal.fire({
                title: "Error",
                text: `Error: Failed to fetch user data`,
                icon: "error",
                confirmButtonText: "OK"
            }).then(() => {
                store.dispatch("setAuth", false);
                store.dispatch("setToken", { token: null, expiry: null });
                localStorage.clear();
                router.push({ name: "Login" });
            })
        }

        const userData = await userResponse.data;
        const payload = {
          judul: kategori.judul,
          user: userData.id,
        };

        let response;
        if (kategori.id) {
          // Update kategori
          response = await axiosInstance.put(`/kategori/${kategori.id}`, payload, {
            //method: "PUT",
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.value}`,
            },
          });
        } else {
          // Create new kategori
          response = await axiosInstance.post("/kategori", payload, {
            //method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.value}`,
            },
          });
        }

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: kategori.id ? "Kategori berhasil diperbarui" : "Kategori berhasil ditambahkan",
            icon: "success",
            confirmButtonText: "OK"
          }).then(() => {
            kategori.judul = '';
            $('#kategoriModal').modal('hide');
            emit('refreshData'); // Emit event to refresh data
          });
        } else {
          const errorData = await response.data;
          Swal.fire({
            title: "Error",
            text: `Error: ${errorData.message}`,
            icon: "error",
            confirmButtonText: "OK"
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK"
        }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            router.push({ name: "Login" });
        });
      }
    };

    return {
      kategori,
      judulError,
      submit,
    };
  },
};
</script>
