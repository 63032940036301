<template>
    <div class="modal fade" id="msModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ formData.id ? 'Edit Data' : 'Tambah Data' }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="error" class="error-message">{{ error }}</div>
                    <div class="mb-3">
                        <label for="nama" class="form-label">Nama</label>
                        <input type="text" class="form-control" id="nama" v-model="formData.nama" maxlength="50">
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input type="email" class="form-control" id="email" v-model="formData.email">
                    </div>
                    <div class="mb-3">
                        <label for="notelp" class="form-label">No. Telepon</label>
                        <input type="text" class="form-control" id="notelp" v-model="formData.notelp">
                    </div>
                    <div class="mb-3">
                        <label for="jabatan" class="form-label">Jabatan</label>
                        <select class="form-control" id="jabatan" v-model="formData.jabatan">
                            <option :value="1">Sales</option>
                            <option :value="2">Customer Relationship Marketing</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="is_publish" class="form-label">Is Published</label>
                        <select class="form-control" id="is_publish" v-model="formData.is_publish">
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-dismiss="modal">Tutup</button>
                    <button type="button" @click="submit" class="btn btn-primary">Simpan</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, ref, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import axiosInstance from '../../axios';

export default {
  name: 'ModalHub',
  props: {
    isEdit: Boolean,
    editData: Object,
  },
  setup(props, { emit }) {
    const formData = reactive({
      nama: '',
      email: '',
      notelp: '',
      jabatan: '',
      is_publish: '',
      user:null,
    });
    const store = useStore();
    const router = useRouter();
    const token = computed(() => store.state.token);
    const error = ref('');

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get('/user', {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`, 
          },
        });
        if (response.status === 200) {
          formData.user = response.data.id;
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK"
          })
          .then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            window.location.href = '/'
          });
          //throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK"
        })
        .then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = '/'
        });
      }
    };

    const validateForm = () => {
      if (!formData.nama || !formData.email || !formData.notelp || !formData.jabatan) {
        error.value = 'All fields are required';
        return false;
      }
      error.value = '';
      return true;
    };

    watch(
      () => props.editData,
      (newValue) => {
        if (newValue) {
          formData.nama = newValue.nama_lengkap;
          formData.email = newValue.email;
          formData.notelp = newValue.no_tlp;
          formData.is_publish = newValue.is_publish;
          formData.jabatan = newValue.jabatan;
          formData.user = newValue.created_by; 
        } else {
          formData.nama = '';
          formData.email = '';
          formData.notelp = '';
          formData.is_publish = '';
          formData.jabatan = '';
          formData.user = '';
        }
      },
      { immediate: true }
    );

    const submit = () => {
      if (!validateForm()) return;

      if (props.isEdit) {
        emit('updateData', formData);
      } else {
        emit('createData', formData);
      }
    };
    onMounted(fetchUser);
    return {
      formData,
      error,
      submit,
    };
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-bottom: 10px;
}
</style>