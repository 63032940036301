<template>
  <div class="auth-wrapper">
    <div class="auth-content">
      <p class="text-center">
        <router-link class="auth-content-logo-header" to="/">
          <img :src="logo" alt="logo" />
        </router-link>
      </p>
      <div class="card">
        <div class="card-body text-left">
          <h3 class="mb-4 auth-login-title">Login CMS</h3>
          <div v-if="emailError" class="error-message">{{ emailError }}</div>
          <div class="input-group mb-3">
            <input
              type="email"
              class="form-control"
              v-model="user.email"
              placeholder="Email"
              @input="validateEmail"
            />
          </div>
          <div class="input-group mb-4">
            <input
              type="password"
              class="form-control"
              v-model="user.password"
              placeholder="Password"
            />
          </div>
          <button
            class="btn btn-login-auth shadow-2 mb-4 text-white"
            type="button"
            @click.prevent="submit"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";

const logo = "/assets/images/img-bs/logo/logo-white.png";

export default {
  name: "Login",
  setup() {
    // const { proxy } = getCurrentInstance();
    const user = reactive({
      email: "",
      password: "",
    });

    const emailError = ref("");

    const router = useRouter();
    const store = useStore();

    const validateEmail = () => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(user.email)) {
        emailError.value = "Invalid email address.";
      } else {
        emailError.value = "";
      }
    };

    const submit = async () => {
      validateEmail();
      if (emailError.value) {
        return;
      }
      try {
        const response = await axiosInstance.post("/login", user);
        if (response.status === 200) {
          const responseData = response.data;

          if (responseData.code === 200) {
            const { token, tokenExpiry } = responseData.data;
            store.dispatch("setAuth", true);
            store.dispatch("setToken", { token, expiry: tokenExpiry });
            window.location.href = "/dashboard";
            // router.push("/dashboard");
          } else {
            Swal.fire({
              icon: "error",
              title: "Login Failed",
              text: responseData.message,
              confirmButtonText: "OK",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Login Failed",
            text: `Login failed: Username / Password Salah`,
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: `Error: ${error.response?.data?.message || error.message}`,
          confirmButtonText: "OK",
        });
        // performLogoutActions();
      }
    };

    // const performLogoutActions = () => {
    //   store.dispatch("setAuth", false);
    //   store.dispatch("setToken", { token: null, expiry: null });
    //   localStorage.removeItem("token");
    //   localStorage.removeItem("tokenExpiry");
    //   router.replace({ name: "Login" });
    //   // window.location.reload();
    // };

    return {
      user,
      emailError,
      validateEmail,
      submit,
      logo,
    };
  },
};
</script>

<style scoped>
.auth-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(129.86deg, #e20613 45.92%, #ac000a 82.26%);
}

.auth-content {
  width: 450px;
  padding: 15px;
}

.auth-content-logo-header img {
  width: 50%;
  margin: 0 auto;
}

.card {
  border: none;
  background: #f8fafe;
  box-shadow: 0px 4px 50px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 10px;
}

.auth-login-title {
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  color: #232526;
}

.btn-login-auth {
  background: linear-gradient(90deg, #e20613 0%, #f46308 61.98%);
  font-weight: bold;
  font-size: 15px;
  padding: 7px 30px;
  color: #fff;
  display: block;
  width: 100%;
  border-radius: 28px;
  margin-top: 35px;
}

.btn-login-auth:hover {
  background-color: #ac0d0d;
  border-color: #ac0d0d;
}

.error-message {
  color: red;
  font-size: 0.875em;
}
</style>
