<template>
  <div class="content-body" style="padding-top: 10rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <!-- Button Add -->
      <router-link :to="{ name: 'Tambah Career' }" class="btn btn-primary mb-4"
        >Tambah</router-link
      >
      <!-- Tutup -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Data {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <table class="table header-border table-responsive-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Division</th>
                    <th>Jobs</th>
                    <th>Description</th>
                    <th>Company</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!tK || !tK.length" class="text-center">
                    <td colspan="6">No Data</td>
                  </tr>
                  <tr v-else v-for="(item, index) in tK" :key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.divison.name_division }}</td>
                    <td>{{ item.jobstype.name_type }}</td>
                    <td>
                      <div
                        v-html="
                          item.deskripsi
                            ? item.deskripsi.slice(0, 100) + '...'
                            : ''
                        "
                      ></div>
                    </td>
                    <td>{{ item.id_company == 1 ? "Pusat" : "Cabang" }}</td>
                    <td>
                      <router-link
                        :to="{ name: 'Edit Career', params: { id: item.id } }"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fa fa-pencil color-muted"></i>
                      </router-link>
                      <button
                        type="button"
                        @click="confirmDelete(item.id)"
                        class="btn btn-dark btn-rounded"
                      >
                        <i class="fa fa-trash color-muted"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";

export default {
  name: "Career",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const currentRouteName = computed(() => route.name);
    const tK = ref([]);

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        router.replace({ name: "Login" });
      });
      return;
    }

    const fetchData = async () => {
      try {
        const getData = await axiosInstance.get(`/career`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (getData.status === 200) {
          const data = await getData.data;
          if (data.code === 200) {
            tK.value = data.data;

            console.log(tK.value);
          }
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const confirmDelete = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteData(id);
        }
      });
    };

    const deleteData = async (id) => {
      try {
        await axiosInstance.delete(`/career/${id}`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        fetchData();
      } catch (error) {
        Swal.fire("Error", `Failed to delete data: ${error.message}`, "error");
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      currentRouteName,
      tK,
      fetchData,
      deleteData,
      confirmDelete,
    };
  },
};
</script>
