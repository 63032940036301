<template>
  <div class="content-body" style="padding-top: 10rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">Slider</a>
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <!-- Button Add -->
      <router-link
        :to="{ name: 'Tambah Slider Hero' }"
        class="btn btn-primary mb-4"
        >Tambah</router-link
      >
      <!-- Tutup -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Data {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <table class="table header-border table-responsive-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Tittle</th>
                    <th>Deskripsi</th>
                    <th>Image Desktop</th>
                    <th>Image Mobile</th>
                    <th>Url</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!Sh || !Sh.length" class="text-center">
                    <td colspan="6">No Data</td>
                  </tr>
                  <tr v-for="(item, index) in Sh" :key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.title || "No Title" }}</td>
                    <td>{{ item.deskripsi || "No Description" }}</td>
                    <td>
                      <img
                        :src="getImageUrl(item, 'desktop')"
                        :alt="item.alt || 'Gambar desktop'"
                        class="w-100"
                        style="
                          width: 100px;
                          height: 100px;
                          object-fit: cover;
                          border-radius: 5px;
                        "
                      />
                    </td>
                    <td>
                      <img
                        :src="getImageUrl(item, 'mobile')"
                        :alt="item.alt || 'Gambar mobile'"
                        class="w-100"
                        style="
                          width: 100px;
                          height: 100px;
                          object-fit: cover;
                          border-radius: 5px;
                        "
                      />
                    </td>
                    <td>{{ item.url }}</td>
                    <td>{{ item.is_publish ? "Publish" : "Unpublish" }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'Edit Slider Hero',
                          params: { id: item.id },
                        }"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fa fa-pencil color-muted"></i>
                      </router-link>
                      <button
                        type="button"
                        @click="confirmDelete(item.id)"
                        class="btn btn-dark btn-rounded"
                      >
                        <i class="fa fa-trash color-muted"></i>
                      </button>
                      <!-- <router-link :to="{ name: 'View Slider Hero', params: { id: item.id } }" class="btn btn-success btn-rounded">
                                                <i class="fa fa-eye color-muted"></i>
                                        </router-link> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";

export default {
  name: "Slider Hero",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const currentRouteName = computed(() => route.name);
    const message = ref("You are not logged in!");
    const id_kategori = 8;
    const Sh = ref([]);

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        router.replace({ name: "Login" });
      });
      return;
    }

    const fetchData = async () => {
      try {
        const getData = await axiosInstance.get(
          `/slider-kategori/${id_kategori}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        if (getData.status === 200) {
          const data = await getData.data;
          if (data.code === 200) {
            Sh.value = data.data;
          }
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        await Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
        // .then(() => {
        //     store.dispatch("setAuth", false);
        //     store.dispatch("setToken", { token: null, expiry: null });
        //     localStorage.clear();
        //     window.location.href = '/'
        // });
      }
    };

    const confirmDelete = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteData(id);
        }
      });
    };

    const deleteData = async (id) => {
      try {
        const response = await axiosInstance.delete(`/slider/${id}`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        fetchData();
      } catch (error) {
        Swal.fire("Error", `Failed to delete data: ${error.message}`, "error");
      }
    };

    const getImageUrl = (item, type) => {
      if (type === "desktop") {
        return `https://api.dev.bintangsempurna.co.id/images/slider/desktop/${item?.image_desktop}`;
      } else if (type === "mobile") {
        return `https://api.dev.bintangsempurna.co.id/images/slider/mobile/${item?.image_mobile}`;
      }
      return ""; // Return empty string if no image is available
    };

    onMounted(() => {
      fetchData();
    });

    return {
      currentRouteName,
      Sh,
      fetchData,
      deleteData,
      confirmDelete,
      getImageUrl,
    };
  },
};
</script>

<style scoped>
.content-body {
  padding: 30px;
  background: #f4f4f9;
}

table {
  table-layout: fixed;
  width: 100%;
}

thead th {
  font-size: 14px;
}

th,
td {
  word-wrap: break-word;
  white-space: normal;
}

.table-responsive {
  overflow-x: auto;
}
</style>
