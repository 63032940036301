<template>
  <div class="content-body" style="padding-top: 10rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <!-- Button Add -->
      <button
        type="button"
        class="btn btn-primary mb-2"
        data-toggle="modal"
        data-target="#kategoriModal"
        @click="openModal()"
      >
        Tambah
      </button>
      <!-- End Button Add -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Data {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <table class="table header-border table-responsive-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Kategori</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!kategori.length" class="text-center">
                    <td colspan="3">No Data</td>
                  </tr>
                  <tr v-for="(kategori, index) in kategori" :key="kategori.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ kategori.judul }}</td>
                    <!-- <td>
                      <button
                        type="button"
                        class="btn btn-rounded btn-outline-info color-muted"
                        data-toggle="modal"
                        data-target="#kategoriModal"
                        @click="openModal(kategori)"
                      >
                        <i class="fa fa-pencil color-muted"></i>
                      </button>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <Modal :kategoriToEdit="kategoriToEdit" @refreshData="fetchData" />
</template>

<script>
import Modal from "../components/modal/Modal.vue";
import { onMounted, ref, computed} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";

export default {
  name: "Kategori",
  components: {
    Modal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const currentRouteName = computed(() => router.name);
    const kategori = ref([]);
    const kategoriToEdit = ref(null);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    const fetchData = async () => {
        if (!token.value || !tokenExpiry.value || new Date() > new Date(tokenExpiry.value) || !isAuthenticated.value) {
        Swal.fire({
            title: "Error",
            text: `Session expired or invalid`,
            icon: "error",
            confirmButtonText: "OK"
        }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            router.replace({ name: "Login" });
        });
            return;
        }
        try {
            const response = await axiosInstance.get("/kategori",{
            //const response = await fetch("https://api.dev.bintangsempurna.co.id/api/kategori", {
                //method: "GET",
                headers: {
                    Authorization: `Bearer ${token.value}`,
                },
            })

            if(response.status === 200){
                const data = await response.data;
                kategori.value = data.data;
            }else{
                Swal.fire({
                    title: "Error",
                    text: `Error: ${data.message}`,
                    icon: "error",
                    confirmButtonText: "OK"
                });
            }

        } catch (error) {
            await Swal.fire({
                title: "Error",
                text: `Error: ${error.message}`,
                icon: "error",
                confirmButtonText: "OK"
            }).then(() => {
              store.dispatch("setAuth", false);
                store.dispatch("setToken", { token: null, expiry: null });
                localStorage.clear();
                router.push({ name: "Login" });
            });
        }
    };

    const openModal = (kategori = null) => {
      kategoriToEdit.value = kategori;
      $("#kategoriModal").modal("show");
    };

    onMounted(fetchData);

    return {
      currentRouteName,
      kategori,
      kategoriToEdit,
      openModal,
      fetchData,
    };
  },
};
</script>

<style scoped>
.content-body {
  padding: 30px;
  background: #f4f4f9;
}
</style>
