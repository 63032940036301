<template>
  <div class="nav-header">
    <a href="/dashboard" class="brand-logo">
      <img class="logo-abbr" src="/assets/images/img-bs/logo/icon.png" alt="" />
      <img
        class="logo-compact"
        src="/assets/images/img-bs/logo/icon.png"
        alt=""
      />
      <img class="brand-title" src="/assets/images/img-bs/logo/bs.png" alt="" />
    </a>

    <div class="nav-control">
      <div class="hamburger">
        <span class="line"></span><span class="line"></span
        ><span class="line"></span>
      </div>
    </div>
  </div>
</template>

<script></script>
