<template>
  <div class="content-body" style="padding-top: 10rem">
    <div v-if="isLoading">
      <PreLoader />
    </div>
    <!-- row -->

    <div v-else>
      <div class="container-fluid">
        <div class="modal fade" id="addOrderModalside" data-backdrop="static">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Member Card</h5>
                <button type="button" class="close" data-dismiss="modal">
                  <span>&times;</span>
                </button>
              </div>
              <div
                class="modal-body card-apps"
                style="
                  background: linear-gradient(
                    95.46deg,
                    #ed1c24 -20.2%,
                    #f57e20 128.85%
                  );
                "
              >
                <div class="col-xl-12 col-md-6">
                  <div class="card">
                    <div class="card-header border-0 pb-0">
                      <img
                        src="/assets/images/img-bs/content/sobat-icon.png"
                        width="30%"
                      />
                    </div>
                    <!-- modal -->
                    <div class="card-body">
                      <div class="d-flex justify-content-between mt-4">
                        <div class="pr-2">
                          <svg
                            width="20"
                            height="8"
                            viewBox="0 0 20 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="8" rx="4" fill="#E20613" />
                          </svg>
                          <div class="d-flex align-items-center">
                            <img
                              class="icon-member"
                              src="/assets/images/img-bs/content/poin.png"
                              width="30%"
                            />
                            <h4 class="fs-18 text-black font-w600 m-auto">
                              30
                            </h4>
                          </div>
                          <span class="fs-14">Redeem Point</span>
                        </div>
                        <div class="pr-2">
                          <svg
                            width="20"
                            height="8"
                            viewBox="0 0 20 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="8" rx="4" fill="#F4D740" />
                          </svg>
                          <div class="d-flex align-items-center">
                            <img
                              class="icon-member"
                              src="/assets/images/img-bs/content/lucky draw.png"
                              width="30%"
                            />
                            <h4 class="fs-18 text-black font-w600 m-auto">2</h4>
                          </div>

                          <span class="fs-14">Lucky Draw</span>
                        </div>
                      </div>

                      <div class="card-member mt-3">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="d-flex align-items-center">
                              <img
                                class="icon-member mr-3"
                                src="/assets/images/img-bs/content/member_silver.png"
                                width="10%"
                              />
                              <span class="fs-14">Classic Member Card</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="profile-your-expense mt-3">
                        <div class="row">
                          <div class="col-lg-6">
                            <h4 class="fs-18 text-black font-w600 name-profile">
                              Ales Pixie Khumairah
                            </h4>
                          </div>
                          <div class="col-lg-6">
                            <span class="fs-14">Your Expense</span>
                          </div>
                        </div>
                      </div>
                      <div class="range-expense">
                        <div class="progress mb-2">
                          <div
                            class="progress-bar progress-animated bg-primary"
                            style="width: 80%"
                          ></div>
                        </div>
                        <small>Rp. 4,023,750/25,000,000</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-9 col-xxl-8">
            <div class="row">
              <div class="col-xl-12">
                <div class="card event-detail-bx overflow-hidden">
                  <div
                    class="card-body"
                    style="
                      background: linear-gradient(
                        270.02deg,
                        #e20613 30.06%,
                        #ff0000 83.32%
                      );
                    "
                  >
                    <div class="d-flex flex-wrap align-items-center">
                      <h2
                        class="col-xl-6 p-0 col-xxl-12 mr-auto title mb-3 text-white"
                      >
                        Hi,
                        <strong class="text-white">{{ message }}</strong>
                      </h2>
                      <br />
                    </div>
                    <p class="fs-14 text-white">
                      Siap untuk memulai hari kamu <br />untuk mengetahui
                      <strong>Dunia Printing!</strong>
                    </p>
                  </div>
                </div>
              </div>

              <!-- batas -->
              <div class="col-xl-12 col-xxl-12">
                <div class="row">
                  <div class="col-xl-4 col-xxl-4 col-lg-4 col-sm-6">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-end">
                          <div>
                            <p class="fs-14 mb-1">Events yang saya ikut</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6 col-6 border-icon">
                            <img
                              class="img-card-events"
                              src="/assets/images/img-bs/content/icon_event.png"
                            />
                          </div>
                          <div class="col-lg-6 col-6 mt-2">
                            <p class="fs-35 text-black font-w600 text-center">
                              3
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-xxl-4 col-lg-4 col-sm-6">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-end">
                          <div>
                            <p class="fs-14 mb-1">Sertifikat Saya</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6 col-6 border-icon">
                            <img
                              class="img-card-events"
                              src="/assets/images/img-bs/content/icon_sertificate.png"
                            />
                          </div>
                          <div class="col-lg-6 col-6 mt-2">
                            <p class="fs-35 text-black font-w600 text-center">
                              3
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-xxl-4 col-lg-4 col-sm-6">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-end">
                          <div>
                            <p class="fs-14 mb-1">Reviews</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6 col-6 border-icon">
                            <img
                              class="img-card-events"
                              src="/assets/images/img-bs/content/icon_riviews.png"
                            />
                          </div>
                          <div class="col-lg-6 col-6 mt-2">
                            <p class="fs-35 text-black font-w600 text-center">
                              3
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- batas Event Terbaru -->
              <!-- <ListEvent /> -->
              <!-- batas Event Terbaru -->
            </div>
          </div>
          <!-- batas -->

          <div class="col-xl-3 col-xxl-4">
            <div class="row">
              <div class="col-xl-12 col-md-6">
                <div class="card">
                  <div class="card-header border-0 pb-0">
                    <img
                      src="/assets/images/img-bs/content/sobat-icon.png"
                      width="30%"
                    />
                    <a
                      tabindex="0"
                      role="button"
                      data-toggle="popover"
                      data-trigger="focus"
                      title="Perhatian"
                      data-content="Untuk melakukan reddem point hanya dapat dilakukan melalui apps mobile"
                      ><i class="flaticon-381-warning-1"></i
                    ></a>
                  </div>

                  <div class="card-body">
                    <div class="d-flex justify-content-between mt-4">
                      <div class="pr-2">
                        <svg
                          width="20"
                          height="8"
                          viewBox="0 0 20 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="20" height="8" rx="4" fill="#E20613" />
                        </svg>
                        <div class="d-flex align-items-center">
                          <img
                            class="icon-member"
                            src="/assets/images/img-bs/content/poin.png"
                            width="30%"
                          />
                          <h4 class="fs-18 text-black font-w600 m-auto">30</h4>
                        </div>
                        <span class="fs-14">Redeem Point</span>
                      </div>
                      <div class="pr-2">
                        <svg
                          width="20"
                          height="8"
                          viewBox="0 0 20 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="20" height="8" rx="4" fill="#F4D740" />
                        </svg>
                        <div class="d-flex align-items-center">
                          <img
                            class="icon-member"
                            src="/assets/images/img-bs/content/lucky draw.png"
                            width="30%"
                          />
                          <h4 class="fs-18 text-black font-w600 m-auto">2</h4>
                        </div>

                        <span class="fs-14">Lucky Draw</span>
                      </div>
                    </div>
                    <div class="card-member mt-3">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="d-flex align-items-center">
                            <img
                              class="icon-member mr-3"
                              src="/assets/images/img-bs/content/member_silver.png"
                              width="10%"
                            />
                            <span class="fs-14">Classic Member Card</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="profile-your-expense mt-3">
                      <div class="row">
                        <div class="col-lg-6">
                          <h4 class="fs-18 text-black font-w600 name-profile">
                            Ales Pixie Khumairah
                          </h4>
                        </div>
                        <div class="col-lg-6">
                          <span class="fs-14">Your Expense</span>
                        </div>
                      </div>
                    </div>
                    <div class="range-expense">
                      <div class="progress mb-2">
                        <div
                          class="progress-bar progress-animated bg-primary"
                          style="width: 80%"
                        ></div>
                      </div>
                      <small>Rp. 4,023,750/25,000,000</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PreLoader from "../components/PreLoader.vue";
import { onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
import axiosInstance from "../axios";

export default {
  name: "Dashboard",
  components: {
    PreLoader,
  },
  setup() {
    // const { proxy } = getCurrentInstance();
    const message = ref("You are not logged in!");
    const isLoading = ref(true);
    const store = useStore();
    const router = useRouter();
    const user = ref(null);

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);
    const checkAuth = () => {
      if (
        !token.value ||
        new Date(tokenExpiry.value) < new Date() ||
        !isAuthenticated.value
      ) {
        Swal.fire({
          title: "Error",
          text: `Session expired or invalid`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.replace({ name: "Login" });
        });
        return false;
      }
      return true;
    };

    const fetchUserData = async () => {
      isLoading.value = true;
      if (!checkAuth()) return;

      try {
        const response = await axiosInstance.get("/user", {
          //const response = await fetch("https://api.dev.bintangsempurna.co.id/api/user",{
          //method: "GET",
          headers: {
            Authorization: `Bearer ${token.value}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          const content = await response.data;
          user.value = content;
          message.value = `Hi ${content.name}`;
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.status} ${response.statusText}`,
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            router.replace({ name: "Login" });
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Session expired. Please log in again.`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          message.value = "Session expired. Please log in again.";
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.replace({ name: "Login" });
        });
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(fetchUserData);

    watch([isAuthenticated, token], async ([newAuth, newToken]) => {
      if (newAuth && newToken) {
        await fetchUserData();
      }
    });

    return {
      message,
      isLoading,
      user,
      fetchUserData,
    };
  },
};
</script>

<style scoped>
.content-body {
  padding: 30px;
  background: #f4f4f9;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-body {
  position: relative;
  padding: 20px;
}

.card-apps {
  border-radius: 15px;
  color: #fff;
}

.icon-member {
  margin-right: 10px;
}

.name-profile {
  margin-bottom: 10px;
}

.progress-bar {
  background: linear-gradient(95.46deg, #ed1c24 -20.2%, #f57e20 128.85%);
}

.event-detail-bx {
  border-radius: 15px;
}

.img-card-events {
  width: 100%;
  border-radius: 50%;
}

.title {
  font-size: 24px;
  font-weight: 600;
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px;
}

.fs-35 {
  font-size: 35px;
}

.font-w600 {
  font-weight: 600;
}

.text-black {
  color: #000;
}
</style>

<!-- <script>
import PreLoader from "../components/PreLoader.vue";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "Dashboard",
  setup() {
    const message = ref("You are not logged in!");
    const store = useStore();
    const router = useRouter();

    onMounted(async () => {
      try {
        const token = store.state.token;
        console.log("Token from store:", token); // Debug: Log the token

        if (!token) {
          message.value = "Token not found!";
          throw new Error("No token found");
        }

        // Make an API request with the Bearer token
        const response = await fetch(
          "https://api.dev.bintangsempurna.co.id/api/user",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Include the Bearer token
            },
          }
        );

        const content = await response.json();
        console.log("User data:", content); // Debug: Log the user data

        if (response.ok) {
          message.value = `${content.name}`;
          await store.dispatch("setAuth", true);
          await store.dispatch("setUser", content); // Ensure user data is set
        } else {
          console.error("Failed to fetch user data:", content.message);
          message.value = "Failed to fetch user data.";
          await store.dispatch("setAuth", false);
          router.replace({ name: "Login" });
        }
      } catch (error) {
        console.error("Error:", error);
        message.value = "An error occurred.";
        await store.dispatch("setAuth", false);
        router.replace({ name: "Login" });
      }
    });

    const logout = async () => {
      try {
        await store.dispatch("logout"); // Call logout action
        window.location.href = "/"; // Redirect to login
      } catch (error) {
        console.error("Logout failed:", error);
      }
    };

    return {
      message,
      logout,
    };
  },
};
</script> -->
