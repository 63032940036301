import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";
import Profile from "@/views/Profile.vue";
import Sertifikat from "@/views/Sertifikat.vue";
import kategori from "@/views/Kategori.vue";
import Blog from "@/views/Blog.vue";
import AddBlog from "@/views/Posts/AddBlog.vue";
import EditBlog from "@/views/Posts/EditBlog.vue";
import Award from "@/views/Award.vue";
import AddAward from "@/views/Posts/AddAward.vue";
import EditAward from "@/views/Posts/EditAward.vue";
import ArtikelEvent from "@/views/ArtikelEvent.vue";
import AddArtikelEvent from "@/views/Posts/AddArtikelEvent.vue";
import EditArtikelEvent from "@/views/Posts/EditArtikelEvent.vue";
import EventRecap from "@/views/EventRecap.vue";
import AddEventRecap from "@/views/Posts/AddEventRecap.vue";
import EditEventRecap from "@/views/Posts/EditEventRecap.vue";
import Testimoni from "@/views/Testimoni.vue";
import AddTestimoni from "@/views/Posts/AddTestimoni.vue";
import EditTestimoni from "@/views/Posts/EditTestimoni.vue";

import store from "../store/index"; // Import the Vuex store
import HubungiKami from "@/views/HubungiKami.vue";
import TentangKami from "@/views/TentangKami.vue";
import SubKategori from "@/views/SubKategori.vue";
import AddTk from "@/views/Posts/AddTk.vue";
import EditTk from "@/views/Posts/EditTk.vue";
import Journey from "@/views/Journey.vue";
import ViewTk from "@/views/Posts/ViewTk.vue";
import AddSliderHero from "@/views/Posts/AddSliderHero.vue";
import EditSliderHero from "@/views/Posts/EditSliderHero.vue";
import ViewSliderHero from "@/views/Posts/ViewSliderHero.vue";
import SliderHero from "@/views/SliderHero.vue";
import SliderProductHome from "@/views/SliderProductHome.vue";
import AddSliderProductHome from "@/views/Posts/AddSliderProductHome.vue";
import EditSliderProductHome from "@/views/Posts/EditSliderProductHome.vue";
import SliderAwardHome from "@/views/SliderAwardHome.vue";
import AddSliderAwardHome from "@/views/Posts/AddSliderAwardHome.vue";
import EditSliderAwardHome from "@/views/Posts/EditSliderAwardHome.vue";
import Testimonial from "@/views/Testimonial.vue";
import AddTesti from "@/views/Posts/AddTesti.vue";
import EditTesti from "@/views/Posts/EditTesti.vue";
//import ViewTesti from "@/views/Posts/ViewTesti.vue";
import Product from "@/views/Product.vue";
import AddProduct from "@/views/Posts/AddProduct.vue";
import EditProduct from "@/views/Posts/EditProduct.vue";
import ViewProduct from "@/views/Posts/ViewProduct.vue";
import Bussines from "@/views/Bussines.vue";
import AddBussines from "@/views/Posts/AddBussines.vue";
import EditBussines from "@/views/Posts/EditBussines.vue";
import Teknologi from "@/views/Teknologi.vue";
import AddTeknologi from "@/views/Posts/AddTeknologi.vue";
import EditTeknologi from "@/views/Posts/EditTeknologi.vue";
// import UserList from "@/views/UserList.vue";
// import AddUser from "@/views/Posts/AddUser.vue";
// import EditUser from "@/views/Posts/EditUser.vue";
import Career from "@/views/Career.vue";
import AddCareer from "@/views/Posts/AddCareer.vue";
import EditCareer from "@/views/Posts/EditCareer.vue";
import Division from "@/views/Division.vue";
import Jobs from "@/views/Jobs.vue";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/division",
    name: "Division",
    component: Division,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/sertifikat",
    name: "Sertifikat",
    component: Sertifikat,
    meta: { requiresAuth: true },
  },
  {
    path: "/kategori",
    name: "Kategori",
    component: kategori,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/sub-kategori",
    name: "Sub Kategori",
    component: SubKategori,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/hubungi-kami",
    name: "Hubungi Kami",
    component: HubungiKami,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/tentang-kami",
    name: "Tentang Kami",
    component: TentangKami,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/tk/add",
    name: "Tambah Tentang Kami",
    component: AddTk,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/tk/edit/:id",
    name: "Edit Tentang Kami",
    component: EditTk,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/tk/view/:id",
    name: "View Tentang Kami",
    component: ViewTk,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/slider-hero",
    name: "Slider Hero",
    component: SliderHero,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/slider-hero/add",
    name: "Tambah Slider Hero",
    component: AddSliderHero,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/slider-hero/edit/:id",
    name: "Edit Slider Hero",
    component: EditSliderHero,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  // {
  //   path: "/slider-hero/view/:id",
  //   name: "View Slider Hero",
  //   component: ViewSliderHero,
  //   beforeEnter: (to, from, next) => {
  //     const isAuthenticated = store.getters.isAuthenticated;
  //     const token = store.getters.token;
  //     const tokenExpiry = store.getters.tokenExpiry;
  //     if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
  //       next("/");
  //     else next();
  //   },
  // },
  {
    path: "/slider-product-home",
    name: "Slider Product Home",
    component: SliderProductHome,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/slider-product-home/add",
    name: "Tambah Slider Product Home",
    component: AddSliderProductHome,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/slider-product-home/edit/:id",
    name: "Edit Slider Product Home",
    component: EditSliderProductHome,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/slider-award-home",
    name: "Slider Award Home",
    component: SliderAwardHome,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/slider-award-home/add",
    name: "Tambah Slider Award Home",
    component: AddSliderAwardHome,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/slider-award-home/edit/:id",
    name: "Edit Slider Award Home",
    component: EditSliderAwardHome,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/blog/add",
    name: "Tambah Blog",
    component: AddBlog,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/blog/edit/:id",
    name: "Edit Blog",
    component: EditBlog,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/award",
    name: "Award",
    component: Award,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/award/add",
    name: "Tambah Award",
    component: AddAward,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/award/edit/:id",
    name: "Edit Award",
    component: EditAward,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/art-event",
    name: "Artikel Event",
    component: ArtikelEvent,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/art-event/add",
    name: "Tambah Artikel Event",
    component: AddArtikelEvent,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/art-event/edit/:id",
    name: "Edit Artikel Event",
    component: EditArtikelEvent,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/event-recap",
    name: "Event Recap",
    component: EventRecap,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/event-recap/add",
    name: "Tambah Event Recap",
    component: AddEventRecap,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/event-recap/edit/:id",
    name: "Edit Event Recap",
    component: EditEventRecap,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/testimoni",
    name: "Testimoni",
    component: Testimoni,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/testimoni/add",
    name: "Tambah Testimoni",
    component: AddTestimoni,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/testimoni/edit/:id",
    name: "Edit Testimoni",
    component: EditTestimoni,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/journey",
    name: "Journey",
    component: Journey,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/testi",
    name: "Testimonial",
    component: Testimonial,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/testi/add",
    name: "Tambah Testimonial",
    component: AddTesti,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/testi/edit/:id",
    name: "Edit Testimonial",
    component: EditTesti,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  // {
  //   path: "/testi/view/:id",
  //   name: "View Testimonial",
  //   component: ViewTesti,
  //   beforeEnter: (to, from, next) => {
  //     const isAuthenticated = store.getters.isAuthenticated;
  //     const token = store.getters.token;
  //     const tokenExpiry = store.getters.tokenExpiry;
  //     if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
  //       next("/");
  //     else next();
  //   },
  // },
  {
    path: "/products",
    name: "Product",
    component: Product,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/product/add",
    name: "Tambah Product",
    component: AddProduct,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/product/edit/:id",
    name: "Edit Product",
    component: EditProduct,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/product/view/:id",
    name: "View Product",
    component: ViewProduct,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/bussines",
    name: "Bussines",
    component: Bussines,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/bussines/a",
    name: "Tambah Bussines",
    component: AddBussines,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/bussines/edit/:id",
    name: "Edit Bussines",
    component: EditBussines,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/teknologi",
    name: "Teknologi",
    component: Teknologi,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/teknologi/add",
    name: "Tambah Teknologi",
    component: AddTeknologi,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/teknologi/edit/:id",
    name: "Edit Teknologi",
    component: EditTeknologi,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  // {
  //   path: "/users",
  //   name: "User List",
  //   component: UserList,
  //   beforeEnter: (to, from, next) => {
  //     const isAuthenticated = store.getters.isAuthenticated;
  //     const token = store.getters.token;
  //     const tokenExpiry = store.getters.tokenExpiry;
  //     if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
  //       next("/");
  //     else next();
  //   },
  // },
  // {
  //   path: "/users/add",
  //   name: "Tambah User",
  //   component: AddUser,
  //   beforeEnter: (to, from, next) => {
  //     const isAuthenticated = store.getters.isAuthenticated;
  //     const token = store.getters.token;
  //     const tokenExpiry = store.getters.tokenExpiry;
  //     if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
  //       next("/");
  //     else next();
  //   },
  // },
  // {
  //   path: "/users/edit/:id",
  //   name: "Edit User",
  //   component: EditUser,
  //   beforeEnter: (to, from, next) => {
  //     const isAuthenticated = store.getters.isAuthenticated;
  //     const token = store.getters.token;
  //     const tokenExpiry = store.getters.tokenExpiry;
  //     if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
  //       next("/");
  //     else next();
  //   },
  // },
  {
    path: "/career",
    name: "Career",
    component: Career,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/career/add",
    name: "Tambah Career",
    component: AddCareer,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/career/edit/:id",
    name: "Edit Career",
    component: EditCareer,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
        next("/");
      else next();
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard to protect routes
// router.beforeEach((to, from, next) => {
//   const isAuthenticated = store.getters.isAuthenticated;
//   const tokenExpiry = store.getters.tokenExpiry;

//   if (to.matched.some((record) => record.meta.requiresAuth) && (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())) {
//     next('/');
//   } else {
//     next();
//   }
// });

// export default router;

// Navigation guard to protect routes
// router.beforeEach((to, from, next) => {
//   const isAuthenticated = store.getters.isAuthenticated;
//   const token = store.getters.token;
//   const tokenExpiry = store.getters.tokenExpiry;
//   if (
//     to.matched.some((record) => record.meta.requiresAuth) &&
//     (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
//   ) {
//     next("/");
//   } else {
//     next();
//   }
//   // if (to.path === "/" && isAuthenticated) {
//   //   next({ name: "Dashboard" });
//   // } else if (to.path !== "/" && !isAuthenticated) {
//   //   next("/");
//   // } else if (
//   //   to.matched.some((record) => record.meta.requiresAuth) &&
//   //   (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
//   // ) {
//   //   next("/");
//   // } else {
//   //   next();
//   // }
// });

// export default router;

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const token = store.getters.token;
  const tokenExpiry = store.getters.tokenExpiry;

  // Jika pengguna sudah login dan mencoba mengakses halaman login ("/")
  if (
    to.path === "/" &&
    isAuthenticated &&
    token &&
    new Date(tokenExpiry) > new Date()
  ) {
    // Redirect ke dashboard
    next("/dashboard");
  }
  // Jika pengguna belum login dan mencoba mengakses halaman yang memerlukan autentikasi
  else if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
  ) {
    next("/");
  }
  // Jika tidak ada masalah, lanjutkan ke halaman yang dituju
  else {
    next();
  }
});

export default router;
