<template>
  <div class="content-body" style="padding-top: 10rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">Post</a>
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <!-- Button Add -->
      <router-link
        :to="{ name: 'Tambah Testimonial' }"
        class="btn btn-primary mb-4"
        >Tambah</router-link
      >
      <!-- Tutup -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Data {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <table class="table header-border table-responsive-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Customer</th>
                    <th>Deskripsi</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!tK || !tK.length" class="text-center">
                    <td colspan="6">No Data</td>
                  </tr>
                  <tr v-else v-for="(item, index) in tK" :key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <div class="media align-items-center">
                        <img
                          class="img-fluid rounded mr-3 d-none d-xl-inline-block"
                          width="70"
                          :src="getImageUrl(item)"
                        />
                        <div class="media-body">
                          <h4 class="font-w600 mb-1">
                            <a href="javascript:void(0)" class="text-black">
                              {{ item.nama }}</a
                            >
                          </h4>
                          <span>{{ item.company }}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span
                        class="star-review d-inline-block mb-2 fs-16 wspace-no"
                      >
                        <i
                          v-for="i in 5"
                          :key="i"
                          :class="getStarClass(item.rating, i)"
                          class="fa fs-16"
                        ></i>
                      </span>
                      <div
                        v-html="truncateText(item.deskripsi, 50)"
                        class="mt-2"
                      ></div>
                    </td>
                    <td>{{ item.publish ? "Publish" : "No" }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'Edit Testimonial',
                          params: { id: item.id },
                        }"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fa fa-pencil color-muted"></i>
                      </router-link>
                      <button
                        type="button"
                        @click="confirmDelete(item.id)"
                        class="btn btn-dark btn-rounded"
                      >
                        <i class="fa fa-trash color-muted"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";

export default {
  name: "Testimonial",

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const currentRouteName = computed(() => route.name);
    const message = ref("You are not logged in!");
    const tK = ref([]);

    const id_kategori = 2;
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        router.replace({ name: "Login" });
      });
      return;
    }

    const fetchData = async () => {
      try {
        const getData = await axiosInstance.get(
          `/testi-kategori/${id_kategori}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        if (getData.status === 200) {
          const data = await getData.data;
          if (data.code === 200) {
            tK.value = data.data;
          }
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        await Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
        // .then(() => {
        //     store.dispatch("setAuth", false);
        //     store.dispatch("setToken", { token: null, expiry: null });
        //     localStorage.clear();
        //     window.location.href = '/'
        // });
      }
    };

    const confirmDelete = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteData(id);
        }
      });
    };

    const deleteData = async (id) => {
      try {
        const response = await axiosInstance.delete(`/testi/${id}`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        fetchData();
      } catch (error) {
        Swal.fire("Error", `Failed to delete data: ${error.message}`, "error");
      }
    };

    const getStarClass = (rating, index) => {
      return rating && rating >= index
        ? "fa-star text-orange"
        : "fa-star text-gray";
    };
    const getImageUrl = (item) => {
      if (item) {
        return `https://api.dev.bintangsempurna.co.id/images/testi/${item?.image}`;
      }
      return "";
    };

    const truncateText = (text, length) => {
      return text.length > length ? text.slice(0, length) + "..." : text;
    };

    onMounted(() => {
      fetchData();
    });

    return {
      currentRouteName,
      tK,
      fetchData,
      deleteData,
      confirmDelete,
      getImageUrl,
      getStarClass,
      truncateText,
    };
  },
};
</script>
