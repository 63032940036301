<template>
    <div class="content-body" style="padding-top: 10rem">
      <div class="container-fluid">
        <div class="page-titles">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Product' }">Data Produk</router-link>
            </li>
            <li class="breadcrumb-item">
              <a href="javascript:void(0)">{{ currentRouteName }}</a>
            </li>
          </ol>
        </div>
  
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Form {{ currentRouteName }}</h4>
              </div>
              <div class="card-body">
                <div class="basic-form">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Nama Produk</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.title"
                      @input="generateSlug"
                      type="text"
                      class="form-control"
                      placeholder="Nama Produk"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Slug URL</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.slug"
                      type="text"
                      class="form-control"
                      placeholder="Slug URL"
                      readonly
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Kategori</label>
                  <div class="col-sm-9">
                    <select v-model="post.id_sub_kategori" class="form-control">
                      <option
                        v-for="kategori in kategoriList"
                        :key="kategori.id"
                        :value="kategori.id"
                      >
                        {{ kategori.judul }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Thumbnail</label>
                  <div class="col-sm-9">
                      <div v-if="post.imagePreview" class="mb-3">
                      <img
                        :src="post.imagePreview"
                        alt="Thumbnail"
                        class="img-thumbnail"
                        style="max-width: 200px; max-height: 200px"
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        class="d-none"
                        ref="fileInput"
                        accept="image/*"
                        @change="handleFileUpload"
                      />
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="triggerFilePicker"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Image Caption</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.alt_image"
                      type="text"
                      class="form-control"
                      placeholder="Input Caption Image"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Deskripsi</label>
                  <textarea
                    ref="summernote"
                    class="form-control summernote"
                    id="deskripsi"
                    v-model="post.deskripsi"
                    rows="3"
                  ></textarea>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Keyword Tag</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.hastag"
                      type="text"
                      class="form-control"
                      placeholder="Input Keyword Tag"
                    />
                  </div>
                </div>

                <div class="card-header2 mt-5 mb-3">
                  <h4 class="card-title">Meta SEO</h4>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Meta Title</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_title"
                      type="text"
                      class="form-control"
                      placeholder="Input Meta Title"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label"
                    >Meta Description</label
                  >
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_deskripsi"
                      type="text"
                      class="form-control"
                      placeholder="Max. 160 Characters"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Meta Content</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_content"
                      type="text"
                      class="form-control"
                      placeholder="Input Meta Content"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Meta Name</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_name"
                      type="text"
                      class="form-control"
                      placeholder="Input Meta Content"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Status</label>
                  <div class="col-sm-9">
                    <select v-model="post.is_publish" class="form-control">
                      <option value="true">Publish</option>
                      <option value="false">Unpublish</option>
                    </select>
                  </div>
                </div>
                <button type="button" @click="goBack" class="btn btn-warning">
                  Kembali
                </button>
                <button type="button" @click="updateData" class="btn btn-primary">
                  Update
                </button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { computed, onMounted, ref, nextTick, watch } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import Swal from "sweetalert2";
  import axiosInstance from "../../axios";
  
  export default {
    name: "Edit Product",
    setup() {
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      const summernote = ref(null);
      const fileInput = ref(null);
      const kategoriList = ref([]);
      const currentRouteName = computed(() => route.name);
  
      const post = ref({
        title: "",
        slug: "",
        image: null,
        alt_image: "",
        deskripsi: "",
        hastag: "",
        meta_title: "",
        meta_deskripsi: "",
        meta_content: "",
        meta_name: "",
        url_produk: "",
        is_publish: "",
        user: null,
        id_kategori: 4,
        id_sub_kategori: null,
    });
  
      const isAuthenticated = computed(() => store.getters.isAuthenticated);
      const token = computed(() => store.getters.token);
      const tokenExpiry = computed(() => store.getters.tokenExpiry);
      const id_kategori = post.value.id_kategori;
  
      if (!token.value || !tokenExpiry.value || new Date() > new Date(tokenExpiry.value) || !isAuthenticated.value) {
        Swal.fire({
          title: "Error",
          text: `Session expired or invalid`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.replace({ name: "Login" });
        });
        return;
      }
  
      const fetchUser = async () => {
        try {
          const response = await axiosInstance.get("/user", {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          });
          if (response.status === 200) {
            post.value.user = response.data.id;
          } else {
            throw new Error("User not found");
          }
        } catch (error) {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            router.replace({ name: "Login" });
          });
        }
      };

      const fetchData = async () => {
        const id = route.params.id;
        try {
          const response = await axiosInstance.get(`/product/${id}`, {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          });

          if (response.status === 200) {
              console.log(response);
            const imageUrl = `https://api.dev.bintangsempurna.co.id/images/product/${response.data.data.image}`;
            post.value = {
              ...post.value,
              title: response.data.data.title,
              slug: response.data.data.slug,
              alt_image: response.data.data.alt_image,
              deskripsi: response.data.data.deskripsi,
              url_produk: response.data.data.url_produk,
              hastag: response.data.data.hastag,
              meta_title: response.data.data.meta_title,
              meta_deskripsi: response.data.data.meta_deskripsi,
              meta_content: response.data.data.meta_content,
              meta_name: response.data.data.meta_name,
              is_publish: response.data.data.is_publish,
              user: post.value.user || null,
              id_kategori: response.data.data.id_kategori || 4,
              id_sub_kategori: response.data.data.id_sub_kategori,
              imagePreview: response.data.data.image ? imageUrl : null,
              image: response.data.data.image ? response.data.data.image : null,
            };
            $(summernote.value).summernote("code", post.value.deskripsi);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      const sub_kategori = async () => {
        try {
          const response = await axiosInstance.get(`/kat-sub/${id_kategori}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          });
          if (response.status === 200) {
            kategoriList.value = response.data.data;
          } else {
            Swal.fire({
              title: "Error",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
            //throw new Error('Failed to fetch user data');
          }
        } catch (error) {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            window.location.href = "/";
          });
        }
      };
  
    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        post.value.image = file;
        post.value.imagePreview = URL.createObjectURL(file);
      }
    };

    const triggerFilePicker = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };
  
      const goBack = () => {
        router.push({ name: "Product" });
      };
  
      const generateSlug = () => {
        post.value.slug = post.value.title
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-")
          .replace(/^-+|-+$/g, "");
      };
  
    const updateData = async () => {
        post.value.deskripsi = $(summernote.value).summernote("code");
        const formData = new FormData();
        Object.keys(post.value).forEach((key) => {
            if (
            key !== "image" &&
            key !== "imagePreview" &&
            post.value[key] !== ""
            ) {
            formData.append(key, post.value[key]);
            }
        });

        if (post.value.image instanceof File) {
            formData.append("image", post.value.image);
        } else if (typeof post.value.image === "string") {
            formData.append("image", post.value.image);
        }
  
        try {
          const response = await axiosInstance.post(`/product-update/${route.params.id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token.value}`,
            },
          });
  
          if (response.status === 200) {
            Swal.fire({
              title: "Success",
              text: "Data created successfully!",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              goBack();
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "Error creating data",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          Swal.fire({
            title: "Error",
            text: `Error: ${error.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
    };
  
    onMounted(async() => {
    await fetchUser();
    await fetchData();
    await sub_kategori();
        nextTick(() => {
            $(summernote.value).summernote({
            height: 200,
            callbacks: {
                onChange: (contents) => {
                post.value.deskripsi = contents;
                },
            },
            });
        });
    });

    watch(
        post,
        () => post.value.id_kategori,
        async (newKategori, oldKategori) => {
          if (newKategori !== oldKategori) {
            console.log("id_kategori changed:", newKategori);
            await sub_kategori();
          }
        },
        { deep: true }
    );
  
      return {
        post,
        fetchUser,
        currentRouteName,
        generateSlug,
        updateData,
        goBack,
        handleFileUpload,
        summernote,
        triggerFilePicker,
        fileInput,
        kategoriList
      };
    },
  };
  </script>
  