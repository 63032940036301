<template>
  <div class="content-body" style="padding-top: 10rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Bussines' }">Data Bussines</router-link>
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Form {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Title</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.title"
                      type="text"
                      class="form-control"
                      placeholder="Title"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Jenis</label>
                  <div class="col-sm-9">
                    <select v-model="post.jenis" class="form-control">
                      <option value="">Pilih Jenis</option>
                      <option value="1">Retail</option>
                      <option value="2">B2B</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Foto</label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          @change="handleFileUpload"
                          type="file"
                          class="custom-file-input"
                        />
                        <label class="custom-file-label">{{
                          post.image ? post.image.name : "Pilih file"
                        }}</label>
                      </div>
                    </div>
                    <!-- Preview Gambar -->
                    <div v-if="imagePreview" class="mt-3">
                      <img
                        :src="imagePreview"
                        alt="Preview"
                        class="img-thumbnail"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Deskripsi</label>
                  <div class="col-sm-9">
                    <textarea
                      ref="summernote"
                      class="form-control summernote"
                      id="deskripsi"
                      v-model="post.deskripsi"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Link URL</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.url_wa"
                      type="url"
                      class="form-control"
                      placeholder="Enter a valid link URL"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Status</label>
                  <div class="col-sm-9">
                    <select v-model="post.is_publish" class="form-control">
                      <option value="true">Publish</option>
                      <option value="false">Unpublish</option>
                    </select>
                  </div>
                </div>
                <button type="button" @click="goBack" class="btn btn-warning">
                  Kembali
                </button>
                <button type="button" @click="submit" class="btn btn-primary">
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "Tambah Bussines",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const summernote = ref(null);
    const imagePreview = ref(null);
    const currentRouteName = computed(() => route.name);

    const post = ref({
      title: "",
      jenis: "",
      image: null,
      deskripsi: "",
      url_wa: "",
      is_publish: "",
      user: null,
    });

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        router.replace({ name: "Login" });
      });
      return;
    }

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          post.value.user = response.data.id;
        } else {
          throw new Error("User not found");
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.replace({ name: "Login" });
        });
      }
    };

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      post.value.image = file;

      // Membuat pratinjau gambar
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          imagePreview.value = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    };

    const goBack = () => {
      router.push({ name: "Bussines" });
    };

    const submit = async () => {
      post.value.deskripsi = $(summernote.value).summernote("code");
      const formData = new FormData();
      formData.append("title", post.value.title);
      formData.append("jenis", post.value.jenis);
      formData.append("deskripsi", post.value.deskripsi);
      formData.append("url_wa", post.value.url_wa);
      formData.append("is_publish", post.value.is_publish);
      formData.append("user", post.value.user);
      if (post.value.image) {
        formData.append("image", post.value.image);
      }

      try {
        const response = await axiosInstance.post("/bussines", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Data created successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            goBack();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Error creating data",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    onMounted(async () => {
      await fetchUser();
      nextTick(() => {
        $(summernote.value).summernote({
          height: 200,
          callbacks: {
            onChange: (contents) => {
              post.value.deskripsi = contents;
            },
          },
        });
      });
    });

    return {
      post,
      fetchUser,
      currentRouteName,
      submit,
      goBack,
      handleFileUpload,
      summernote,
      imagePreview,
    };
  },
};
</script>
