<template>
    <div class="content-body" style="padding-top: 10rem">

        <div class="container-fluid">
            <div class="page-titles">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">{{ currentRouteName }}</a></li>
                </ol>
            </div>

            <!-- Button Add -->
            <button
                type="button"
                class="btn btn-primary mb-2"
                data-toggle="modal"
                data-target="#JyModal"
                @click="openModal()"
            >
                Tambah
            </button>
            <!-- End Button Add -->

            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Data {{ currentRouteName }}</h4>
                        </div>
                        <div class="card-body">
                        <table class="table header-border table-responsive-sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Tahun</th>
                                    <th>Deskripsi</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="!journey || !journey.length" class="text-center">
                                    <td colspan="6">No Data</td>
                                </tr>
                                <tr v-for="(item, index) in journey" :key="item.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.judul }}</td>
                                    <td>{{ item.tahun }}</td>
                                    <td v-html="item.deskripsi"></td>
                                    <td>
                                        <button
                                            @click="openModal(true, item)"
                                            type="button"
                                            class="btn btn-primary btn-rounded"
                                            data-toggle="modal"
                                            data-target="#JyModal"
                                        >
                                        <i class="fa fa-pencil color-muted"></i>
                                        </button>
                                        <button @click="deleteData(item.id)" type="button" class="btn btn-dark btn-rounded"><i class="fa fa-trash color-muted"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <ModalJy :isEdit="isEdit" :editData="editData" @createData="createData" @updateData="updateData"/>
</template>
<script>
import { computed, onMounted, ref, watch,nextTick  } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";
import ModalJy from "../components/modal/ModalJy.vue";

export default {
  name: "Journey",
  components: {
    ModalJy,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const journey = ref([]);
    const isEdit = ref(false);
    const editData = ref(null);
    const currentRouteName = computed(() => router.name);
    const message = ref("You are not logged in!");

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if(!token.value || !tokenExpiry.value || new Date() > new Date(tokenExpiry.value) || !isAuthenticated.value) {
        Swal.fire({
            title: "Error",
            text: `Session expired or invalid`,
            icon: "error",
            confirmButtonText: "OK"
        }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            router.replace({ name: "Login" });
        });
        return;
    }

    const openModal = (edit = false, data = null) => {
      isEdit.value = edit;
      editData.value = data;
      nextTick(() => {
        $('#JyModal').modal('show');
        
        // Reset atau set ulang Summernote
        if (data) {
        $("#deskripsi").summernote("code", data.deskripsi);
        } else {
        $("#deskripsi").summernote("reset");
        }
    });
    };

    const fetchData = async () => {

        try {
            const getData = await axiosInstance.get("/ms",{
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.value}`,
                },
            });

            if(getData.status === 200) {
                const data = await getData.data;
                if(data.code === 200) {
                    journey.value = data.data;
                }
            }else{
                Swal.fire({
                    title: "Error",
                    text: `Error: ${data.message}`,
                    icon: "error",
                    confirmButtonText: "OK"
                });
            }

        }catch (error) {
            await Swal.fire({
                title: "Error",
                text: `Error: ${error.message}`,
                icon: "error",
                confirmButtonText: "OK"
            })
        }
    };

    const createData = async (formData) => {
      try {
          const response = await axiosInstance.post("/ms", formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          await Swal.fire({
            title: "Success",
            text: "Data created successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
          await fetchData();
          $('#JyModal').modal('hide');
        } else {
          const errorData = await response.data;
          Swal.fire({
            title: "Error",
            text: `Error: ${errorData.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const updateData = async (formData) => {
      try {
        const response = await axiosInstance.put(`/ms/${editData.value.id}`, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          await Swal.fire({
            title: "Success",
            text: "Data updated successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
          await fetchData();
          $('#JyModal').modal('hide');
        } else {
          const errorData = await response.data;
          Swal.fire({
            title: "Error",
            text: `Error: ${errorData.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const deleteData = async (id) => {
      try {
        const response = await axiosInstance.delete(`/ms/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          await Swal.fire({
            title: "Success",
            text: "Data deleted successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
          await fetchData();
          $('#JyModal').modal('hide');
        } else {
          const errorData = await response.json();
          Swal.fire({
            title: "Error",
            text: `Error: ${errorData.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    
    onMounted(() => {
        fetchData();
    });

    watch([isAuthenticated, token], async ([newAuth, newToken]) => {
      if (newAuth && newToken) {
        await fetchData();
      }
    });
    
    return {
      currentRouteName,
      message,
      journey,
      isEdit,
      editData,
      openModal,
      createData,
      updateData,
      deleteData,
      fetchData
    };
 }
}
</script>
