<template>
  <div class="content-body" style="padding-top: 10rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">Slider</a>
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <!-- Button Add -->
      <router-link :to="{ name: 'Tambah Product' }" class="btn btn-primary mb-4"
        >Tambah</router-link
      >
      <!-- Tutup -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Data {{ currentRouteName }}</h4>
              <!-- Search bar -->
              <div class="d-flex align-items-center">
                <input
                  v-model="searchQuery"
                  @input="handleSearch"
                  class="form-control mr-2"
                  placeholder="Cari berdasarkan judul..."
                  style="width: 250px"
                />
                <button @click="handleSearch" class="btn btn-primary">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <table class="table header-border table-responsive-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Produk</th>
                    <th>Kategori</th>
                    <th>Deskripsi</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!paginatedData.length" class="text-center">
                    <td colspan="6">No Data</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(item, index) in paginatedData"
                    :key="item.id"
                  >
                    <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                    <td>
                      <div class="media align-items-center">
                        <img
                          class="img-fluid rounded mr-3 d-none d-xl-inline-block"
                          width="70"
                          :src="getImageUrl(item)"
                        />
                        <div class="media-body">
                          <h4 class="font-w600 mb-1 wspace-no">
                            <a href="javascript:void(0)" class="text-black">
                              {{ item.title }}</a
                            >
                          </h4>
                          <span>{{ item.url_produk }}</span>
                        </div>
                      </div>
                    </td>
                    <td>{{ item.sub_kategori }}</td>
                    <td>
                      <span
                        class="star-review d-inline-block mb-2 fs-16 wspace-no"
                      >
                        <i
                          v-for="i in 5"
                          :key="i"
                          :class="getStarClass(item.rating, i)"
                          class="fa fs-16"
                        ></i>
                      </span>
                      <div v-html="item.deskripsi" class="mt-2"></div>
                    </td>
                    <td>{{ item.is_publish ? "Publish" : "No" }}</td>
                    <td>
                      <router-link
                        :to="{ name: 'Edit Product', params: { id: item.id } }"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fa fa-pencil color-muted"></i>
                      </router-link>
                      <button
                        type="button"
                        @click="confirmDelete(item.id)"
                        class="btn btn-dark btn-rounded"
                      >
                        <i class="fa fa-trash color-muted"></i>
                      </button>
                      <button
                        @click="openModal(item.id)"
                        class="btn btn-info btn-rounded"
                      >
                        <i class="fa fa-eye color-muted"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- Pagination -->
              <nav>
                <ul class="pagination justify-content-center">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 1 }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="prevPage"
                      >Previous</a
                    >
                  </li>
                  <li
                    v-for="page in totalPages"
                    :key="page"
                    class="page-item"
                    :class="{ active: page === currentPage }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="goToPage(page)"
                    >
                      {{ page }}
                    </a>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === totalPages }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="nextPage"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Detail Product -->
    <ProductDetailModal
      v-if="showModal"
      :productId="selectedProductId"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";
import ProductDetailModal from "../components/modal/ModalProduct.vue";

export default {
  name: "Product",
  components: { ProductDetailModal },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const currentRouteName = computed(() => route.name);
    const tK = ref([]);
    const showModal = ref(false);
    const selectedProductId = ref(null);
    // const id_kategori = 4;

    const currentPage = ref(1);
    const perPage = 10;
    const searchQuery = ref("");
    const totalItems = ref(0);

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        router.replace({ name: "Login" });
      });
      return;
    }

    // const fetchData = async () => {
    //   try {
    //     const getData = await axiosInstance.get(`/product`, {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token.value}`,
    //       },
    //     });

    //     if (getData.status === 200) {
    //       const data = await getData.data;
    //       if (data.code === 200) {
    //         tK.value = data.data;
    //         totalItems.value = data.data.length;
    //         console.log("Data fetched:", tK.value); // Debugging line
    //       }
    //     } else {
    //       Swal.fire({
    //         title: "Error",
    //         text: `Error: ${data.message}`,
    //         icon: "error",
    //         confirmButtonText: "OK",
    //       });
    //     }
    //   } catch (error) {
    //     Swal.fire({
    //       title: "Error",
    //       text: `Error: ${error.message}`,
    //       icon: "error",
    //       confirmButtonText: "OK",
    //     });
    //   }
    // };

    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/product`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200 && response.data.code === 200) {
          // Assuming the structure of response.data is { code: 200, data: { product: [...] } }
          tK.value = response.data.data.product || []; // Assign the product array to tK
          totalItems.value = tK.value.length;
          console.log("Data fetched:", tK.value); // Debugging line
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message || "Failed to fetch data"}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const filteredData = computed(() => {
      if (Array.isArray(tK.value)) {
        return tK.value.filter((item) => {
          return item.title
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase());
        });
      }
      return [];
    });

    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * perPage;
      const end = start + perPage;
      return filteredData.value.slice(start, end);
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredData.value.length / perPage);
    });

    const handleSearch = () => {
      currentPage.value = 1; // Reset to first page on search
    };

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const confirmDelete = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteData(id);
        }
      });
    };

    const deleteData = async (id) => {
      try {
        await axiosInstance.delete(`/product/${id}`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        fetchData();
      } catch (error) {
        Swal.fire("Error", `Failed to delete data: ${error.message}`, "error");
      }
    };

    const getStarClass = (rating, index) => {
      return rating && rating >= index
        ? "fa-star text-orange"
        : "fa-star text-gray";
    };

    const getImageUrl = (item) => {
      if (item) {
        return `https://api.dev.bintangsempurna.co.id/images/product/${item?.image}`;
      }
      return "";
    };

    const openModal = (id) => {
      selectedProductId.value = id;
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
      selectedProductId.value = null;
    };

    onMounted(() => {
      fetchData();
    });

    return {
      currentRouteName,
      tK,
      fetchData,
      deleteData,
      confirmDelete,
      getImageUrl,
      getStarClass,
      openModal,
      closeModal,
      showModal,
      selectedProductId,
      searchQuery,
      handleSearch,
      paginatedData,
      currentPage,
      totalPages,
      goToPage,
      perPage,
      nextPage,
      prevPage,
    };
  },
};
</script>
