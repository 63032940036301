<template>
  <div class="content-body">
    <!-- row -->
    <div class="container-fluid">
      <!-- batas -->

      <div class="card">
        <div class="card-body">
          <div class="profile-tab">
            <div class="custom-tab-1">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    href="#about-me"
                    data-toggle="tab"
                    class="nav-link active show"
                    >Profile</a
                  >
                </li>
                <li class="nav-item">
                  <a href="#history" data-toggle="tab" class="nav-link"
                    >Riwayat Events</a
                  >
                </li>
              </ul>
              <div class="tab-content">
                <div id="about-me" class="tab-pane fade active show">
                  <div class="profile-personal-info mt-3">
                    <h4 class="text-primary mb-4">Informasi Pribadi</h4>
                    <div class="row mb-2">
                      <div class="col-sm-3 col-5">
                        <h5 class="f-w-500">
                          Nama <span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-sm-9 col-7"><span>Ales</span></div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-sm-3 col-5">
                        <h5 class="f-w-500">
                          Email <span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-sm-9 col-7">
                        <span>ales@bintangsempurna.co.id</span>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-sm-3 col-5">
                        <h5 class="f-w-500">
                          No Handphone <span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-sm-9 col-7">
                        <span>089655222584</span>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-sm-3 col-5">
                        <h5 class="f-w-500">
                          Alamat<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-sm-9 col-7">
                        <span>Jl. Aren III no 14B</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="history" class="tab-pane fade">
                  <div class="history mt-3">
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="tab-content">
                          <div class="tab-pane active">
                            <div class="table-responsive fs-14">
                              <table
                                class="table mb-4 dataTablesCard no-hover card-table fs-14"
                                id="example5"
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <div class="media align-items-center">
                                        <img
                                          class="img-fluid rounded mr-3 d-none d-xl-inline-block"
                                          width="70"
                                          src="/assets/images/img-bs/content/complete.png"
                                          alt="DexignZone"
                                        />
                                        <div class="media-body">
                                          <h4 class="font-w600 mb-1 wspace-no">
                                            <a
                                              href="javascript:void(0)"
                                              class="text-black"
                                              >Site Visit</a
                                            >
                                          </h4>
                                          <span
                                            >Jumat, 24 Maret 2023 02:00
                                            WIB</span
                                          >
                                        </div>
                                      </div>
                                    </td>
                                    <td class="d-none d-lg-table-cell">
                                      PT. Bintang Sempurna
                                    </td>
                                    <td>
                                      <span
                                        class="star-review d-inline-block mb-2 fs-16 wspace-no"
                                      >
                                        <i
                                          class="fa fa-star fs-16 text-orange"
                                        ></i>
                                        <i
                                          class="fa fa-star fs-16 text-orange"
                                        ></i>
                                        <i
                                          class="fa fa-star fs-16 text-orange"
                                        ></i>
                                        <i
                                          class="fa fa-star fs-16 text-orange"
                                        ></i>
                                        <i
                                          class="fa fa-star fs-16 text-gray"
                                        ></i>
                                      </span>
                                      <p class="mb-0 d-none d-xl-inline-block">
                                        Lorem Ipsum is simply dummy text of the
                                        printing and typesetting industry.
                                      </p>
                                    </td>
                                    <td>
                                      <div class="d-flex">
                                        <a
                                          href="sertifikat.html"
                                          class="btn btn-success btn-sm light px-4"
                                          >Sertifikat</a
                                        >
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <!-- add -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
};
</script>
