import { createStore } from "vuex";

export default createStore({
  state: {
    isAuthenticated: !!localStorage.getItem('token'),
    token: localStorage.getItem('token') || null,
    tokenExpiry: localStorage.getItem('tokenExpiry') || null,
  },
  mutations: {
    setAuth(state, status) {
      state.isAuthenticated = status;
    },
    setToken(state, { token, expiry }) {
      state.token = token;
      state.tokenExpiry = expiry;
      if (token) {
        localStorage.setItem("token", token);
        localStorage.setItem("tokenExpiry", expiry);
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiry");
      }
    },
  },
  actions: {
    setAuth({ commit }, status) {
      commit("setAuth", status);
    },
    setToken({ commit }, payload) {
      commit("setToken", payload);
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    token: (state) => state.token,
    tokenExpiry: (state) => state.tokenExpiry,
  },
});
