<template>
  <div>
    <router-view v-if="!isAuthenticated" />
    <div v-else>
      <!-- eslint-disable vue/no-multiple-template-root -->
      <!-- <PreLoader /> -->
      <div id="main-wrapper">
        <NavHeader />
        <ChatBox />
        <Header />
        <LeftMenu />

        <!-- Body Content -->
        <router-view />
        <!-- End Body Content -->
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";

// Import components used in the main app view
import NavHeader from "@/components/NavHeader.vue";
import ChatBox from "@/components/ChatBox.vue";
import Header from "@/components/Header.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    NavHeader,
    ChatBox,
    Header,
    LeftMenu,
    Footer,
  },
  setup() {
    const store = useStore();

    // Computed property to check if the user is authenticated
    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    return {
      isAuthenticated,
    };
  },
};
</script>

<style></style>
