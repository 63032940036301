// import { createApp } from "vue";
// import App from "./App.vue";
// import router from "./router";
// import store from "./store/index";
// // import authStore from "./store/auth";
// // import axios from "axios";

// // axios.defaults.withCredentials = true;
// // axios.defaults.baseURL = "https://api.dev.bintangsempurna.co.id/";

// // authStore.dispatch("user").then(() => {
// //   createApp(App).use(store).use(router).mount("#app");
// // });

// createApp(App).use(store).use(router).mount("#app");

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import axios from "./axios";

// Set the default base URL for Axios
axios.defaults.baseURL = "https://api.dev.bintangsempurna.co.id/api";
axios.defaults.withCredentials = true; // Use this if your API requires credentials

// Make Axios available globally via the Vue prototype
const app = createApp(App);
app.config.globalProperties.$axios = axios;

// Initialize your app
app.use(store).use(router).mount("#app");
